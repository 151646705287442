<template>
    <div class="dynamic-container" :class="{ hideFromThird: !foldAll }">

        <template v-if="listData && listData.length" >
            <div class="content">

                <dl class="item-group" v-for="(item, index) in listData" :key="index" >
                    <dt class="publisher-label" v-if="item.dynamic_type === 1">#项目发起</dt>
                    <dt class="publisher-label" v-else-if="item.dynamic_type === 2">#项目进度更新</dt>
                    <dt class="publisher-label" v-else-if="item.dynamic_type === 3">#修改目标金额</dt>

                    <dd class="publisher-user-info">
                        <img v-lazy="item.face_url" />
                        <span class="name">{{item.publish_name}}</span>
                        <span class="time">{{ item.publish_time_unix | distinct24Hours }} </span>
                    </dd>

                    <dd class="publisher-desc" v-if="item.content">
                         <!--项目发起-->
                        <div v-if="item.dynamic_type === 1" class="item">
                            <span>筹款目标金额</span>
                            <p v-html="item.content"></p>
                        </div>
                        <!--修改项目金额-->
                        <div v-else-if="item.dynamic_type === 3" class="item">
                            <span>修改目标金额</span>
                            <p v-html="item.content"></p>
                        </div>

                        <div v-else class="item">
                            <p v-html="item.content"></p>
                        </div>
                    </dd>

                    <!--更新进度-->
                    <template v-if="item.dynamic_type === 2">
                        <dd v-viewer class="imgBox" v-if="item.images && item.images.length">
                            <div class="aspect-ratio-box" v-for="(itemImg, index) in item.images" :key="index">
                                <img
                                    v-lazy="itemImg+'?x-image-process=style/love.png'"
                                    :data-preview="itemImg+'?x-image-process=style/love.png'" class="preview-img"  />
                                <i class="banner-bg" :style="{'backgroundImage':'url('+ itemImg +'?x-image-process=style/thumb.png)'}"></i>
                            </div>
                        </dd>
                    </template>
                </dl>

                <div class="list-loading-status" v-if="foldAll">
                    <p v-if="loadState === 'more'" @click="$emit('loadMore')" class="load-more">加载更多</p>
                    <p v-else-if="loadState === 'loading'">正在加载...</p>
                </div>

                <div v-if="listData.length > 2"
                    @click="showHideFromThird"
                    class="showMore">
                    {{ !foldAll ? '查看全部' : '收起' }}
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import '@/common/js/directives/useViewer';
export default {
    name:'DynamicList',
    data() {
        return {
            foldAll: false,
        }
    },
    props: {
        listData: {
            type: Array,
            default: []
        },
        loadState: {
            type: String,
            default: 'loading',
        }
    },
    methods: {
        showHideFromThird() {
            this.foldAll = !this.foldAll;

            // 收起时，滚动显示第一条
            if (!this.foldAll) {
                const ele_dynamic = document.querySelector(".publish-dynamic");
                window.scrollTo({
                    top: ele_dynamic.offsetTop,
                    behavior: 'smooth'
                });
            }
        }
    },
}
</script>

<style lang='less' scoped>
.dynamic-container {
    .content {
        background: #f7f7f7;
        font-size: 12px;
        border-radius: 4px;
        padding: 24px 12px;
    }

    .item-group {
        font-size: 14px;
        margin: 24px 0 0;
        &:first-of-type {
            margin: 0;
        }
        dt {
            color: #119e34;
            font-weight: 600;
            margin: 0;
        }
        .publisher-label {
            color: #0099FF;
            text-align: left;
        }

        dd.p {
            line-height: 1.5;
            margin-top: 10px;
            margin-bottom: -4px;
            font-size: 14px;
            color: #333;
        }
        dd {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 12px 0 0;
        }

        .imgBox {
            margin-top: 8px;
            justify-content: flex-start;
            flex-wrap: wrap;
            margin-right: -2%;
        }
        .aspect-ratio-box {
            flex-shrink: 0;
            border-radius: 4px;
            overflow: hidden;
            position: relative;
            width: 19.10vw;
            height: 19.10vw;
            margin: 0 2.4vw 2.4vw 0;

            .banner-bg {
                display: block;
                width: 19.10vw;
                height: 19.10vw;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                position: relative;
            }
            .preview-img {
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
                width: 100%;
                height: 100%;
            }
        }

        .aspect-ratio-box:nth-child(4n) {
            margin-right: 0;
        }

        .info {
            font-size: 12px;
            color: #0099FF;
            position: relative;
            padding-left: 9px;
            margin-top: 8px;
            line-height: 1;
            &::before {
                position: absolute;
                left: 0;
                top: 1px;
                content: '';
                width: 4px;
                height: 8px;
                border-bottom: 1px solid #0099FF;
                border-right: 1px solid #0099FF;
                display: block;
                transform: rotateZ(36deg);
            }
        }
        .special {
            color: #0099FF;
            font-weight: 500;
        }
    }

    .publisher-user-info {
        color: #999;
        align-items: center;
        display: flex;
        margin-top: 16px;

        img {
            display: block;
            width: 20px;
            height: 20px;
            border-radius: 10px;
            margin-right: 12px;
        }
        .name {
            display: block;
            font-size:14px;
            max-width: 12em;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .time {
            margin-left: auto;
            font-size: 12px;
        }
    }

    .publisher-desc {
        text-align: left;
        margin-top: 16px;
        .item {
            display: flex;
            align-items: center;
            word-break: break-all;

            > span {
                margin-right: 10px;
                font-size: 14px;
                color: #999999;
            }
        }

        p {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #333;

            /deep/ span {
                color: #0099FF;
                font-weight: 500;
            }
        }
    }

    .list-loading-status {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        text-align: center;
        line-height: 40px;
        padding-top: 16px;

        .load-more {
            color: #0099FF;
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: underline;
        }

        .arrow-down {
            width: 9px;
            height: 9px;
            border-bottom: 1px solid #0099FF;
            border-right: 1px solid #0099FF;
            box-shadow: 0 0 0 lightgray;
            -webkit-transition: all 200ms ease;
            transition: all 200ms ease;
            transform: rotate(45deg);
            top: -3px;
            position: relative;
            margin-left: 5px;
        }
    }
}


.showMore {
    font-size: 12px;
    color: #999;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 16px;
    display: flex;
    &::after {
        content: '';
        display: block;
        width: 12px;
        height: 12px;
        background: url('~@/assets/icon-tirangle-right.png') center / 12px no-repeat;
        margin-left: 2px;
        transform: rotateZ(-90deg);
    }
}

.hideFromThird {
    dl {
        display: none;
        &:first-of-type {
            display: block;
        }
        &:nth-child(2) {
            display: block;
        }
    }
    .showMore {
        &::after {
            transform: rotateZ(90deg);
        }
    }
}
</style>
